import { selectors } from '@/app/store';
import { SIDE_NAVIGATION_LINKS } from '@app/constants/sideNavigationLinks';
import { FC } from 'react';
import { useSelector } from 'react-redux';
import SideNavigation from '../SideNavigation';

interface Props {
  className?: string;
}

const ClientProfileSideNavigation: FC<Props> = (props) => {
  const metadata = useSelector(selectors.auth.selectMetadata);

  const sidebarNavigationLinks = metadata?.isB2B ? SIDE_NAVIGATION_LINKS.b2bClientProfile : SIDE_NAVIGATION_LINKS.clientProfile;

  return (
    <SideNavigation {...props} data={sidebarNavigationLinks} />
  );
};

export default ClientProfileSideNavigation;
